import React from "react";
import { useLocation, useMatches } from "@remix-run/react";
import type { Location, Params } from "@remix-run/react";
import type { ScriptDescriptor as OriginalScriptDescriptor } from "remix-utils/external-scripts";
import { useHydrated } from "remix-utils/use-hydrated";

export type HandleConventionArguments<Data = unknown> = {
  id: string;
  data: Data;
  params: Params;
  matches: ReturnType<typeof useMatches>;
  location: Location;
  parentsData: {
    [routeId: string]: any;
  };
};

export function ExternalHeadScripts({ nonce }: { nonce?: string }) {
  let scripts = useExternalHeadScripts();

  return (
    <>
      {scripts.map((props) => {
        return <ExternalHeadScript key={props.src} {...props} nonce={nonce} />;
      })}
    </>
  );
}

export type ScriptDescriptor = OriginalScriptDescriptor & {
  dataset?: Record<string, string>;
};

export interface ExternalScriptsFunction<Loader = unknown> {
  (args: HandleConventionArguments<Loader>): ScriptDescriptor[];
}

export interface ExternalScriptsHandle<Data = unknown> {
  scripts?: ExternalScriptsFunction<Data> | ScriptDescriptor[];
  headScripts?: ExternalScriptsFunction<Data> | ScriptDescriptor[];
}

export function ExternalHeadScript({
  src,
  preload = false,
  async = true,
  defer = true,
  crossOrigin,
  integrity,
  type,
  referrerPolicy,
  noModule,
  nonce,
  dataset,
}: ScriptDescriptor & { dataset?: Record<string, string> }) {
  let isHydrated = useHydrated();
  let startsHydrated = React.useRef(isHydrated);

  React.useEffect(() => {
    if (!startsHydrated.current && isHydrated) return;

    let $script = document.createElement("script");
    $script.src = src;

    let attributes = {
      async,
      defer,
      crossOrigin,
      integrity,
      type,
      referrerPolicy,
      noModule,
      nonce,
      dataset,
    };

    for (let [key, value] of Object.entries(attributes)) {
      if (key !== "dataset" && value)
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        $script.setAttribute(key, value.toString());
    }
    if (dataset) {
      for (let [key, value] of Object.entries(dataset)) {
        $script.dataset[key] = value;
      }
    }

    document.head.append($script);

    return () => $script.remove();
  }, [
    async,
    crossOrigin,
    defer,
    integrity,
    isHydrated,
    noModule,
    nonce,
    referrerPolicy,
    src,
    type,
    dataset,
  ]);

  if (startsHydrated.current && isHydrated) return null;

  let rel = noModule ? "modulepreload" : "preload";
  let as = noModule ? undefined : "script";

  return (
    <>
      {preload && (
        <link
          rel={rel}
          href={src}
          as={as}
          crossOrigin={crossOrigin}
          integrity={integrity}
          referrerPolicy={referrerPolicy}
        />
      )}
      <script
        src={src}
        defer={defer}
        async={async}
        type={type}
        noModule={noModule}
        nonce={nonce}
        crossOrigin={crossOrigin}
        integrity={integrity}
        referrerPolicy={referrerPolicy}
      />
    </>
  );
}

export function useExternalHeadScripts() {
  let location = useLocation();

  let matches = useMatches();

  return React.useMemo(() => {
    let scripts = matches.flatMap((match, index, matches) => {
      if (!match.handle) return []; // ignore no-handle routes
      if (match.handle === null) return []; // ignore null handles
      if (typeof match.handle !== "object") return []; // and non error handles
      if (!("headScripts" in match.handle)) return []; // and without scripts

      let scripts = match.handle.headScripts as
        | ExternalScriptsFunction
        | ScriptDescriptor[];

      // if scripts is an array, suppose it's an array of script descriptors
      // and return it
      if (Array.isArray(scripts)) return scripts;

      // if it's not a function (and not an array), ignore it
      if (typeof scripts !== "function") return [];

      let result = scripts({
        id: match.id,
        data: match.data,
        params: match.params,
        location,
        parentsData: matches.slice(0, index).map((match) => match.data),
        matches,
      });

      if (Array.isArray(result)) return result;
      return [];
    });

    let uniqueScripts = new Map<string, ScriptDescriptor>();
    for (let script of scripts) uniqueScripts.set(script.src, script);
    return [...uniqueScripts.values()];
  }, [matches, location]);
}
